import "./App.css";
import IconButton from "./icons/IconButton";
import { Github, Linkedin, Instagram } from "./icons";
import React from "react";

function Home() {
  return (
    <div
      className="home bg-cover"
      style={{ backgroundImage: `url(./majakas.jpg)` }}
    >
      <header className="header backdrop-blur-lg">
        <div className=" uppercase p-2 sm:p-20 py-24 mx-auto max-w-xs sm:max-w-none bg-white rounded-xl shadow-xl flex items-center">
          <div>
            <p className="text-6xl font-medium pb-5 text-black">
              Kustav Prants
            </p>
            <p className="text-slate-500 text-2xl">
              Software Developer <br /> @{" "}
              <a
                href="https://snowhound.eu/"
                target="_blank"
                className="text-blue-400"
                rel="noreferrer"
              >
                Snowhound
              </a>
            </p>
            <div className="mt-10">
              <IconButton site="https://github.com/LordzShadow/">
                <Github />
              </IconButton>
              <IconButton site="https://www.linkedin.com/in/kustav-prants/">
                <Linkedin />
              </IconButton>
              <IconButton site="https://www.instagram.com/kustavprants/">
                <Instagram />
              </IconButton>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Home;
