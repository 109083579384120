import React from "react";

interface Props {
  children: any;
  site: string;
}

function IconButton(props: Props) {
  return (
    <a className="mx-1" href={props.site} target="_blank" rel="noreferrer">
      <button className="transition duration-500 bg-transparent hover:bg-blue-300 text-blue-700 font-semibold hover:text-white p-2 border border-blue-500 hover:border-transparent rounded-full">
        {props.children}
      </button>
    </a>
  );
}

export default IconButton;
